




















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissLab6ReflectionPart2',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        reflectionPart2: null,
      },
      questions: [
        {
          text: 'During these past three weeks, what have you learned about designing a scientific experiment to answer a question? What are the things that could possibly cause an error in your measurement(s) and therefore your conclusion. ',
          inputName: 'reflectionPart2',
        },
      ],
    };
  },
};
